import React, { Component } from 'react';
import classNames from 'classnames';

import './header.scss'

const STYLE_BASE = 'header_'

class Header extends Component {
  render() {
    const {titleFirst, titleSecond, titleThird, className} = this.props;

    return (
      <div className={classNames(`${STYLE_BASE}wrapper`, {
        [className]: !!className
      })}>
        {!!titleFirst && (
          <div className={`${STYLE_BASE}titleFirst`}>
            <span>{titleFirst}</span>
          </div>
        )}

        {!!titleSecond && (
          <div className={`${STYLE_BASE}titleSecond`}>
            <span>{titleSecond}</span>
          </div>
        )}

        {!!titleThird && (
          <div className={`${STYLE_BASE}titleThird`}>
            <span>{titleThird}</span>
          </div>
        )}
      </div>
    );
  }
}

export default Header;