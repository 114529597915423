export const store = {
    get: (key) => localStorage.getItem(key),
    set: (key, value) => localStorage.setItem(key, value),
    remove: (key) => localStorage.removeItem(key),
    getObject: (key) => JSON.parse(localStorage.getItem(key)),
    setObject: (key, value) => localStorage.setItem(key, JSON.stringify(value)),
}

export const STORAGE_KEY = {
    AUTH: 'auth',
    CREDENTIALS: 'credentials',
    CUSTOMER_ACCOUNTS_INFO: 'customerAccountsInfo',
    PROFILE: 'profile',
    APPLICATIONS: 'apps',
    SELECTED_APPLICATION: 'selectedApp',
    KEYCLOAK_AUTH: 'keycloak',
}