import React from "react";

export const removeArrayElementOnce = (array, value) => {
  var index = array.indexOf(value);
  if (index > -1) {
    array.splice(index, 1);
    return true;
  }
  return false;
}

export const parseQuery = queryString => {
  const query = {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export const cloneElements = (children, props) => {
  if (Array.isArray(children)) {
    return React.Children.map(children.filter(child => !!child), (child =>
        typeof child === 'object' ? React.cloneElement(child, props) : child
    ))
  } else {
    return React.cloneElement(children, props)
  }
}

export const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  const aClone = [...a].sort();
  const bClone = [...b].sort();
  for (let i = 0; i < aClone.length; ++i) {
    if (aClone[i] !== bClone[i]) return false;
  }
  return true;
}