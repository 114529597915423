import axios from "axios";
import {
    APP_ID,
    BASE_URL,
    CLIENT_ID,
    CLIENT_SECRET,
    FI_ID,
    USER_EMAIL,
    USER_FIRST_NAME,
    USER_LAST_NAME
} from "../data/constants";
import { STORAGE_KEY, store } from "../data/store";

const tokenHeaders = {
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json",
    ApplicationId: APP_ID,
};

export const defaultAxios = axios.create({
    headers: {
        ApplicationId: APP_ID,
    },
});
defaultAxios.interceptors.request.use(function (config) {
    config.headers.Authorization =  store.get(STORAGE_KEY.AUTH);//data.securityToken.tokenString;
    return config;
});
const keycloakAxios = axios.create();

export const defaultErrorHandler = (methodName, error) => {
    if (error?.response) {
        // assume it's axios error object
        console.log(`${methodName} Error: `, error.response.data?.causedBy);
        throw error.response.data?.causedBy;
    }
    console.error(`${methodName} Error: `, error);
    throw error;
};

export const fetchSessionStart = async () => {
    try {
        const tokenData = `clientId=${CLIENT_ID}&clientSecret=${CLIENT_SECRET}`;
        const { data } = await defaultAxios.post(`${BASE_URL}/session/start`, tokenData, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "application/json",
            },
        });
        //console.log('fetchSessionStart', data);
        return data;
    } catch (error) {
        defaultErrorHandler('fetchSessionStart', error);
    }
};

export const fetchUser = async ({ username }) => {
    try {
        const { data } = await defaultAxios.post(`${BASE_URL}/user`, {
            userName: username,
            fiMasterId: FI_ID,
        });
        //console.log('fetchUser', data);
        return data;
    } catch (error) {
        defaultErrorHandler('fetchUser', error);
    }
};

export const fetchUserAuthenticate = async ({ username, password, passwordReset, code, otp }) => {
    try {
        const { data } = await defaultAxios.post(`${BASE_URL}/user/authenticate`, {
            userName: username,
            passWord: password,
            fiMasterId: FI_ID,
            passwordReset: passwordReset,
            code: code,
        });
        //console.log('fetchUserAuthenticate', data);
        return data;
    } catch (error) {
        defaultErrorHandler('fetchUserAuthenticate', error);
    }
};

export const fetchUserForgotPassword = async ({ email }) => {
    try {
        const { data } = await defaultAxios.post(`${BASE_URL}/user/forgot-password`, {
            email
        });
        //console.log('fetchUserForgotPassword', data);
        return data;
    } catch (error) {
        defaultErrorHandler('fetchUserForgotPassword', error);
    }
};

export const fetchFeaturedFIList = async () => {
    try {
        const { data } = await defaultAxios.get(`${BASE_URL}/fs-api/filist/featured`);
        //console.log('fetchFeaturedFIList', data);
        return data;
    } catch (error) {
        console.error('fetchFeaturedFIList', error);
        throw error;
    }
};

export const fetchCustomerInfo = async ({ userId }) => {
    try {
        const { data } = await defaultAxios.get(`${BASE_URL}/tnp/customer/${userId}`);
        //console.log('fetchCustomerInfo', data);
        return data;
    } catch (error) {
        console.error('fetchCustomerInfo', error);
        throw error;
    }
};

export const fetchProfile = async () => {
    try {
        const { data } = await defaultAxios.get(`${BASE_URL}/fs-api/fi/${FI_ID}/profile`);
        //console.log('fetchProfile', data);
        return data;
    } catch (error) {
        console.error('fetchProfile', error);
        throw error;
    }
};

export const fetchCustomerAccountsInfo = async ({ username, password, otp, profile, customerKey }) => {
    try {
        const usernameParam = profile?.signOnInfo?.signOnParams.find(param => param.parameterTypeName === "username");
        const passwordParam = profile?.signOnInfo?.signOnParams.find(param => param.parameterTypeName === "password");
        const otpParam = profile?.signOnInfo?.signOnParams.find(param => param.parameterTypeName === "otpCode");
        const payload = {
            signOnInfoParams: [
                {
                    signOnParamId: usernameParam?.signOnParamId,
                    paramName: usernameParam?.paramName,
                    paramValue: username,
                },
                {
                    signOnParamId: passwordParam?.signOnParamId,
                    paramName: passwordParam?.paramName,
                    paramValue: password,
                },
                ...( // conditionally add otp param data to array
                  otp
                    ? [{
                        signOnParamId: otpParam?.signOnParamId,
                        paramName: otpParam?.paramName,
                        paramValue: otp,
                    }]
                    : []
                ),
            ],
            customer: customerKey ? {
                customerId: customerKey,
            } : {
                name: { firstName: USER_FIRST_NAME, lastName: USER_LAST_NAME },
                email: USER_EMAIL,
            },
        };
        const { data } = await defaultAxios.post(`${BASE_URL}/fs-api/fi/${FI_ID}/customer/accounts?incBal=N&incApps=Y`, payload);
        //console.log('fetchCustomerAccountsInfo', data);
        return data;
    } catch (error) {
        console.error('fetchCustomerAccountsInfo', error);
        throw error;
    }
};

export const fetchApplicationConsent = async ({ customerAccountsInfo, accounts }) => {
    try {
        const payload = accounts;
        const { fiDirId, customerKey } = customerAccountsInfo;
        const { data } = await defaultAxios.put(`${BASE_URL}/tnp/${fiDirId}/customers/${customerKey}/consent`, payload);
        //console.log('fetchApplicationConsent', data);
        return data;
    } catch (error) {
        console.error('fetchApplicationConsent', error);
        throw error;
    }
};

export const fetchEnroll = async ({ profile, credentials, customerAccountsInfo, accounts }) => {
    try {
        const payload = {
            signOnInfoParams: [
                {
                    signOnParamId: profile?.signOnInfo?.signOnParams.find(param => param.paramName === "USERNAME")?.signOnParamId,
                    paramName: "USERNAME",
                    paramValue: credentials.username,
                },
                {
                    signOnParamId: profile?.signOnInfo?.signOnParams.find(param => param.paramName === "PASSWORD")?.signOnParamId,
                    paramName: "PASSWORD",
                    paramValue: credentials.password,
                },
            ],
            fiDirId: customerAccountsInfo.fiDirId,
            customer: {
                name: { firstName: USER_FIRST_NAME, lastName: USER_LAST_NAME },
                email: USER_EMAIL,
                customerKey: customerAccountsInfo.customerKey,
                customerReferenceId: "786bhbk7y6h;i907887gk87gg9786vbjn",
            },
            accounts,
        };
        const { data } = await defaultAxios.post(`${BASE_URL}/tnp/enroll`, payload);
        //console.log('fetchEnroll', data);
        return data;
    } catch (error) {
        console.error('fetchEnroll', error);
        throw error;
    }
};