import React from 'react';
import classNames from 'classnames';
import './button.scss';

const STYLE_BASE = 'button';

const Button = ({ className, children, isChecked, onClick, title, formButton }) => {
  const handleClick = e => {
    e && e.stopPropagation();
    if (formButton) {
      e && e.preventDefault();
    }
    typeof onClick === 'function' && onClick();
  }

  return (
    <button
      className={classNames(`${STYLE_BASE}`, {
        [`${STYLE_BASE}_checked`]: isChecked,
        [className]: !!className
      })}
      onClick={handleClick}
    >
      <span>{children || title}</span>
    </button>
  )
}

export default Button;
