import React, { useState } from "react";
import { withRouter } from 'react-router-dom';

import "./accounts-page.scss";
import AppHeader from "components/AppHeader";
import PageLayout from "components/MainLayout/PageLayout";
import Panel from "components/Panel";
import Header from "components/MainLayout/Header";
import ButtonPanel from "./ButtonPanel";
import { STORAGE_KEY, store } from "data/store";
import MultiCheckboxChoice from "components/MultiCheckboxChoice";
import { fetchApplicationConsent, fetchEnroll } from "actions/api";
import ErrorWrapper from "components/ErrorWrapper";
import { EXPECTED_ACCOUNT_TYPES, IS_PRODUCTS_FEATURE_ENABLED } from "data/constants";
import MultiCheckboxChoiceDropdown from "components/MultiCheckboxChoiceDropdown";
import FiLogo from "components/FiLogo";
import { arraysEqual } from "utils";

const STYLE_BASE = 'accounts';

const AccountsPage = ({ history }) => {
  const credentials = store.getObject(STORAGE_KEY.CREDENTIALS);
  const customerAccountsInfo = store.getObject(STORAGE_KEY.CUSTOMER_ACCOUNTS_INFO);
  const profile = store.getObject(STORAGE_KEY.PROFILE);
  const selectedApp = store.getObject(STORAGE_KEY.SELECTED_APPLICATION);
  const selectedAppName = selectedApp?.fintechRegistry.name ?? "???";
  const receivedAccountTypes = EXPECTED_ACCOUNT_TYPES.filter(accountType => !!customerAccountsInfo[accountType]);
  
  const allAccounts = receivedAccountTypes.map(accountType => customerAccountsInfo[accountType]).flat();
  const initialSelectedAccounts = [...customerAccountsInfo.associatedApplications?.find(app => app.applicationKey === selectedApp.applicationKey)?.consentedAccounts ?? []];

  // quick mock until backend works
  /*allAccounts.forEach(account => {
    account.availableProducts = [
      "Request for Payment API",
      "Real-Time Payment API"
    ];
    account.associatedProducts = [
      {
        "products": {
          "Request for Payment API": true,
          "Real-Time Payment API": false
        },
        "applicationKey": "74D539F2-2D5B-4ACE-B968-D1F47576CC88"
      },
      {
        "products": {
          "Request for Payment API": false,
          "Real-Time Payment API": true
        },
        "applicationKey": "9331F85A-4B00-44D6-83FA-A9C2D4B19B0C"
      }
    ]
  });*/

  const accountKeyToAllAccountProducts = {};
  allAccounts.forEach(account => {
    const allAccountProducts = account.availableProducts ?? [];
    accountKeyToAllAccountProducts[account.accountKey] = allAccountProducts;
  });
  const accountKeyToInitialSelectedAccountProducts = {};
  allAccounts.forEach(account => {
    const allAccountProducts = account.availableProducts ?? [];
    const accountApplicationProductsSelectedState = account.associatedProducts?.find(item => item.applicationKey === selectedApp.applicationKey)?.products ?? {};
    accountKeyToInitialSelectedAccountProducts[account.accountKey] = allAccountProducts.filter(product => accountApplicationProductsSelectedState[product] === true);
  });

  const [selectedAccounts, setSelectedAccounts] = useState([...initialSelectedAccounts]);
  const [accountKeyToSelectedAccountProducts, setAccountKeyToSelectedAccountProducts] = useState({...accountKeyToInitialSelectedAccountProducts});

  const accountOptions = allAccounts
    .map(account => ({
      label: `${account.displayName || account.accountType || 'ACCOUNT'} Ending in ${account.accountNumber.substring(account.accountNumber.length - 4)}`,
      value: account.accountKey
    }))
    .sort((option1, option2) => option1.label.localeCompare(option2.label));

  const accountKeyToAccountProductOptions = {};
  allAccounts.forEach(account => {
    const allAccountProducts = (account.availableProducts ?? []).sort();
    accountKeyToAccountProductOptions[account.accountKey] = allAccountProducts.map(product => ({
      label: product,
      value: product,
    }))
  });

  const handleSubmitClick = async (setIsLoading) => {
    setSubmitError({
      error: false,
      message:""
    });
    setIsLoading(true);
    try {

      // check if user has changed at least one account's state
      const isStateModified = allAccounts.some(acc =>
        initialSelectedAccounts.includes(acc.accountKey) !== selectedAccounts.includes(acc.accountKey));

      // prepare payload for consent api call
      const accountsWithConsentInfo = {};
      receivedAccountTypes.forEach(accountType => {
        const accountsWithConsentInfoForType = customerAccountsInfo[accountType]
          .filter(acc => initialSelectedAccounts.includes(acc.accountKey) !== selectedAccounts.includes(acc.accountKey)
            || !arraysEqual(accountKeyToInitialSelectedAccountProducts[acc.accountKey], accountKeyToSelectedAccountProducts[acc.accountKey])) // get only accounts that had their state changed by the user
          .map(acc => {
            const allProducts = accountKeyToAllAccountProducts[acc.accountKey];
            const selectedProducts = accountKeyToSelectedAccountProducts[acc.accountKey];
            const associatedProducts = {};
            allProducts.forEach(product => {
              associatedProducts[product] = selectedProducts.includes(product);
            });
            return {
              accountKey: acc.accountKey,
              useCases: [{
                name: selectedApp.fintechRegistry.tnpCncUseCase.useCaseName,
                consented: selectedAccounts.includes(acc.accountKey),
                associatedProducts: associatedProducts
              }]
            }});
        if (accountsWithConsentInfoForType.length > 0) {
          accountsWithConsentInfo[accountType] = accountsWithConsentInfoForType;
        }
      });

      await fetchApplicationConsent({ customerAccountsInfo, accounts: accountsWithConsentInfo });

      // prepare payload for enroll api call
      const accountsWithUpdatedEnrollFlag = {};
      receivedAccountTypes.forEach(accountType => {
        const accountsForType = customerAccountsInfo[accountType];
        accountsForType.forEach(acc => acc.enrolled = selectedAccounts.includes(acc.accountKey));
        accountsWithUpdatedEnrollFlag[accountType] = accountsForType;
      });

      await fetchEnroll({ profile, credentials, customerAccountsInfo, accounts: accountsWithUpdatedEnrollFlag });

      // quick hack to make it look nice for now
      // (apply changes to cached api response without re-querying changes from the server and just assume api call was successful)
      receivedAccountTypes.forEach(accountType => {
        customerAccountsInfo[accountType] = accountsWithUpdatedEnrollFlag[accountType];
      });
      if (!customerAccountsInfo.associatedApplications) {
        customerAccountsInfo.associatedApplications = [];
      }
      let application = customerAccountsInfo.associatedApplications.filter(app => app.applicationKey === selectedApp.applicationKey)[0];
      if (!application) {
        application = { applicationKey: selectedApp.applicationKey };
        customerAccountsInfo.associatedApplications.push(application);
      }
      application.consentedAccounts = [...selectedAccounts];
      store.setObject(STORAGE_KEY.CUSTOMER_ACCOUNTS_INFO, customerAccountsInfo);

      setIsLoading(false);
      if (isStateModified) {
        history.push("/application-instructions");
      } else {
        history.push("/applications");
      }
    } catch (err) {
      console.log(err);
      setSubmitError({
        error: true,
        message: err.message
      })
    }
  }

  const handleCancelClick = () => {
    history.push("/applications");
  }

  const [submitError, setSubmitError] = useState({
    error: false,
    message: "",
  });

  return (
    <>
      <AppHeader />
      <div className={`${STYLE_BASE}_container`}>
        <PageLayout>
          <Header
            titleFirst={
              <FiLogo/>
            }
            titleSecond={`Select the accounts you wish to enable or manage for ${selectedAppName?.trim()}.`}
          />
          <div className={`${STYLE_BASE}_app-logo-container`}>
            <img src={selectedApp?.fintechRegistry.tnpFtrUiAssets.imagePath}/>
          </div>
          <Panel className={`${STYLE_BASE}_list`}>
            <MultiCheckboxChoice
              selectedValue={selectedAccounts}
              options={accountOptions}
              selectAllLabel={"Select all"}
              onChange={(newSelectedValue) => {
                setSelectedAccounts(newSelectedValue);
              }}
              customItemPostfixGenerator={IS_PRODUCTS_FEATURE_ENABLED && ((accountOption) => (
                  <MultiCheckboxChoiceDropdown
                    className={`${STYLE_BASE}_payment-options`}
                    selectedValue={accountKeyToSelectedAccountProducts[accountOption.value]}
                    options={accountKeyToAccountProductOptions[accountOption.value]}
                    selectAllLabel={"Select all"}
                    placeholder={"Payment options"}
                    onChange={(newSelectedValue) => {
                      setAccountKeyToSelectedAccountProducts({
                        ...accountKeyToSelectedAccountProducts,
                        [accountOption.value]: newSelectedValue,
                      });
                    }}
                  />
                ))}
            />
            <ErrorWrapper error={submitError.message}/>
          </Panel>
          <ButtonPanel
            STYLE_BASE={STYLE_BASE}
            handleSubmitClick={handleSubmitClick}
            handleCancelClick={handleCancelClick}
          />
        </PageLayout>
      </div>
    </>
  );
};

export default withRouter(AccountsPage);
