import React, { useEffect } from "react";
import { Paper } from "@mui/material";

import "./generic-message-page-template.scss";
import { withRouter } from "react-router-dom";
import PageLayout from "components/MainLayout/PageLayout";
import Header from "components/MainLayout/Header";
import FiLogo from "components/FiLogo";
import CallToActionButton from "components/CallToActionButton";

import { STYLE_BASE as LOGIN_STYLE_BASE } from '../LoginPage';
import { FI_BACKGROUND_URL } from "data/constants";
import Footer from "pages/LoginPage/Footer";

const STYLE_BASE = 'generic-message-page-template';

const GenericMessagePageTemplate = ({ history, message }) => {
  useEffect(() => {
  }, []);

  return (
    <>
      <div className={`${LOGIN_STYLE_BASE}_container ${STYLE_BASE}_container`} style={{ backgroundImage: `url(${FI_BACKGROUND_URL})` }}>
        <Paper className="form-container">
          <PageLayout>
            <Header
              titleFirst={
                <FiLogo/>
              }
            />
            <div className={`page-content`}>
              <div className={`${STYLE_BASE}_message`}>
                {message}
              </div>
              <CallToActionButton
                onClick={() => history.push("/login")}
                title={"Back to login page"}
              />
            </div>
            <Footer/>
          </PageLayout>
        </Paper>
      </div>
    </>
  );
};

export default withRouter(GenericMessagePageTemplate);
