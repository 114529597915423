import React from "react";
import { FI_LOGO_URL } from "data/constants";

import './fi-logo.scss';

const FiLogo = () => {
  return (
    <div className="fi-logo-container">
      <img
        className="fi-logo"
        src={FI_LOGO_URL}
        alt="fi logo"
      />
    </div>
  );
};

export default FiLogo;
