import React from "react";

import './app-header.scss';

const AppHeader = () => {
  return (
    <div className="app-header-container">
    </div>
  );
};

export default AppHeader;
