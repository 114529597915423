import React from 'react';
import classNames from 'classnames';
import 'components/LabeledCheckbox/labeled-checkbox.scss';
import { Checkbox, FormControlLabel } from "@mui/material";

const STYLE_BASE = 'labeled-checkbox';

const LabeledCheckbox = ({ className, label, value, isChecked, isDisabled, onChange, onClick }) => {
  return (
    <div
      className={classNames(
        `${STYLE_BASE}_wrapper`, {
          [className]: !!className
        })}
    >

      <FormControlLabel
        control={
          <Checkbox
            name={value}
            disableRipple
            checked={!!isChecked}
            disabled={isDisabled}
            icon={<div className={`${STYLE_BASE}_unchecked-icon`}/>}
            indeterminateIcon={<div className={`${STYLE_BASE}_unchecked-icon`}/>}
            checkedIcon={<div className={`${STYLE_BASE}_checked-icon`}/>}
            onChange={(e) => onChange && onChange(value, e.target.checked)}
          />
        }
        onClick={() => onClick && onClick()}
        label={label ?? ""}
      />
    </div>
  )
}

export default LabeledCheckbox;