import React, { useEffect, useState } from "react";
import { CircularProgress, Paper } from "@mui/material";
import validator from 'validator'

import "./forgot-password-page.scss";
import { fetchSessionStart, fetchUserForgotPassword } from "actions/api";
import { STORAGE_KEY, store } from "data/store";
import { withRouter } from "react-router-dom";
import PageLayout from "components/MainLayout/PageLayout";
import Header from "components/MainLayout/Header";
import FiLogo from "components/FiLogo";
import LabeledInput from "components/LabeledInput";
import CallToActionButton from "components/CallToActionButton";

import { STYLE_BASE as LOGIN_STYLE_BASE } from '../LoginPage';
import { FI_BACKGROUND_URL } from "data/constants";
import Footer from "pages/LoginPage/Footer";

const STYLE_BASE = 'forgot-password';

const ForgotPasswordPage = ({ history }) => {
  const [signInLoading, setSignInLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState({
    error: false,
    message: "",
  });

  useEffect(() => {
  }, []);

  async function submitEmail() {
    setSignInLoading(true);
    setEmailError({
      ...emailError,
      error: false,
      message: "",
    });
    try {
      if (!validator.isEmail(email)) {
        throw "invalid email format";
      }

      const sessionData = await fetchSessionStart();
      store.set(STORAGE_KEY.AUTH, sessionData.securityToken.tokenString);
    } catch(error) {
      if (typeof error === 'string') {
        setEmailError({
          ...emailError,
          error: true,
          message: error,
        });
      }
      console.log(error);
      setSignInLoading(false);
      return;
    }

    try {
      // result is ignored intentionally to deny email probing
      await fetchUserForgotPassword({ email });
    } catch { }
    history.push("/forgot-password-success");
  }

  return (
    <>
      <div className={`${LOGIN_STYLE_BASE}_container ${STYLE_BASE}_container`} style={{ backgroundImage: `url(${FI_BACKGROUND_URL})` }}>
        <Paper className="form-container">
          <PageLayout>
            <Header
              titleFirst={
                <FiLogo/>
              }
              titleSecond={"Please enter your email"}
            />
            <div className={`page-content`}>
              <LabeledInput
                className={`${LOGIN_STYLE_BASE}_username`}
                labelText={"Email"}
                isError={emailError.error}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    submitEmail();
                  }
                }}
              />
              <div className={`${LOGIN_STYLE_BASE}_error-message`}>
                {emailError.message}
              </div>
              <CallToActionButton
                onClick={submitEmail}
                title={
                  signInLoading ? <CircularProgress style={{'color': 'white'}} /> : "Submit"
                }
              />
            </div>
            <Footer/>
          </PageLayout>
        </Paper>
      </div>
    </>
  );
};

export default withRouter(ForgotPasswordPage);
