import React, { Component } from 'react';
import classNames from 'classnames';

import './multi-checkbox-choice-dropdown.scss';
import LabeledCheckbox from "../LabeledCheckbox";
import * as utils from "../../utils";
import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";

const STYLE_BASE = 'multi-checkbox-choice-dropdown_';

class MultiCheckboxChoiceDropdown extends Component {

  handleSelectAllCheckboxChange = (key, isChecked) => {
    const { options, onChange } = this.props;
    const newSelectedValue = isChecked ? options.map(option => option.value) : [];
    onChange && onChange(newSelectedValue);
  }

  handleOptionCheckboxChange = (key, isChecked) => {
    const { noneOption, options, onChange } = this.props;
    const selectedValue = [...this.props.selectedValue];

    let newSelectedValue = selectedValue;
    if (isChecked) {
      if (noneOption?.value === key) {
        // if 'none' option is selected, then clear everything else
        newSelectedValue = [noneOption.value];
      } else {
        if (!selectedValue.includes(key)) {
          selectedValue.push(key);
          // keep only actual selected option values (i.e. clear 'none' option)
          const filteredOptionsValues = options.filter(option => selectedValue.includes(option.value)).map(option => option.value);
          newSelectedValue = filteredOptionsValues;
        }
      }
    } else {
      if (utils.removeArrayElementOnce(selectedValue, key)) {
        newSelectedValue = selectedValue;
      }
    }
    onChange && onChange(newSelectedValue);
  }

  handleOptionCheckboxChange1 = (event) => {

  }

  render() {
    const { selectedValue, noneOption, options, selectAllLabel, placeholder, className } = this.props;
    const optionsFirstRowLength = Math.ceil(options.length / 2);
    return (
      <div className={classNames(`${STYLE_BASE}container`, {
          [className]: !!className
        })}
      >
        <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
          <Select
            multiple
            displayEmpty
            value={selectedValue}
            onChange={this.handleOptionCheckboxChange1}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return placeholder;
              }

              return selected.join(', ');
            }}
            MenuProps={{
              className: classNames(`${STYLE_BASE}menu`, {
                [className]: !!className
              })
            }}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {selectAllLabel && (
              <MenuItem
              >
                <div className={`${STYLE_BASE}selectAllCheckboxContainer`}>
                  <LabeledCheckbox
                    className={classNames(
                      `${STYLE_BASE}checkboxElementWrapper`,
                      `${STYLE_BASE}lastCheckboxElementWrapper`
                    )}
                    label={selectAllLabel}
                    value={selectAllLabel}
                    isChecked={options.every(option => selectedValue.includes(option.value))}
                    onChange={this.handleSelectAllCheckboxChange}
                  />
                </div>
              </MenuItem>
            )}
            {options.map((item) => (
              <MenuItem
                key={item.value}
                value={item.value}
              >
                <LabeledCheckbox
                  key={item.value}
                  label={item.label}
                  value={item.value}
                  isChecked={selectedValue.includes(item.value)}
                  onChange={this.handleOptionCheckboxChange}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    )
  };
}

export default MultiCheckboxChoiceDropdown;
