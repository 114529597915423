import { ADMIN_BASE_URL } from "data/constants";
import { defaultAxios, defaultErrorHandler } from "actions/api";

export const fetchFeaturedClientApplications = async () => {
  try {
    const { data } = await defaultAxios.get(`${ADMIN_BASE_URL}/tnp/applications`);
    //console.log('fetchFeaturedClientApplications', data);
    return data;
  } catch (error) {
    defaultErrorHandler('fetchFeaturedClientApplications', error);
  }
};