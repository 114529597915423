import React, { useEffect, useState } from "react";
import { CircularProgress, Paper } from "@mui/material";
import { fetchSessionStart, fetchUserAuthenticate } from "actions/api";
import { withRouter } from "react-router-dom";
import { parseQuery } from "utils";
import jwt_decode from "jwt-decode";
import { STORAGE_KEY, store } from "data/store";

import { STYLE_BASE as LOGIN_STYLE_BASE } from '../LoginPage';
import PageLayout from "components/MainLayout/PageLayout";
import Header from "components/MainLayout/Header";
import FiLogo from "components/FiLogo";
import LabeledInput from "components/LabeledInput";
import CallToActionButton from "components/CallToActionButton";
import FiHelpInstructions from "components/FiHelpInstructions";
import { FI_BACKGROUND_URL } from "data/constants";
import NinthWaveEmpoweredLogo from "components/NinthWaveEmpoweredLogo";

const STYLE_BASE = 'reset-password';

const ResetPasswordPage = ({ history }) => {
  const [signInLoading, setSignInLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [token, setToken] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [usernameError, setUsernameError] = useState({
    error: false,
    message: "",
  });
  const [passwordError, setPasswordError] = useState({
    error: false,
    message: "",
  });

  useEffect(() => {
    try {
      const query = window.location.search;
      if (query) {
        const parsedQuery = parseQuery(query);
        const token = parsedQuery.code ?? parsedQuery.token; // TODO: backend should use only 'token' in the url
        if (!token) {
          throw "invalid reset password url"
        }
        const decodedToken = jwt_decode(token);
        if (Date.now() >= decodedToken.exp * 1000) {
          console.log('now:', Date.now(), 'exp:', decodedToken.exp * 1000, 'token:', decodedToken);
          console.log('now:', new Date(), 'exp:', new Date(decodedToken.exp * 1000), 'issuedAt:', new Date(decodedToken.iat * 1000));
          throw "token has expired";
        }
        setToken(token);
        setUsername(decodedToken.username);
      } else {
        // if no query, then assume we got here simply from login page (expired password etc.)
        const { username } = store.getObject(STORAGE_KEY.CREDENTIALS);
        setUsername(username);
      }
    } catch (e) {
      console.log(e);
      history.push("/reset-password-error");
    }
  }, []);

  async function submitNewPassword() {
    setSignInLoading(true);
    setUsernameError({
      ...usernameError,
      error: false,
      message: "",
    });
    setPasswordError({
      ...usernameError,
      error: false,
      message: "",
    });
    try {
      if (password1 !== password2) {
        setPasswordError({
          ...usernameError,
          error: true,
          message: "passwords dont match",
        });
        throw "passwords dont match";
      }

      const sessionData = await fetchSessionStart();
      store.set(STORAGE_KEY.AUTH, sessionData.securityToken.tokenString);

      const { status, payload: { description } } = await fetchUserAuthenticate({
        username,
        password: password1,
        passwordReset: true,
        code: token,
      });
      if (status === 'SUCCESS') {
        setSignInLoading(false);
        history.push("/login");
      } else {
        setPasswordError({
          ...usernameError,
          error: true,
          message: description,
        });
        throw description;
      }
    } catch(error) {
      console.log(error);
      setSignInLoading(false);
    }
  }

  return (
    <>
      <div className={`${LOGIN_STYLE_BASE}_container ${STYLE_BASE}_container`} style={{ backgroundImage: `url(${FI_BACKGROUND_URL})` }}>
        <Paper className="form-container">
          <PageLayout>
            <Header
              titleFirst={
                <FiLogo/>
              }
              titleSecond={"Reset password"}
            />
            <div className={`page-content`}>
              <LabeledInput
                className={`${LOGIN_STYLE_BASE}_username`}
                labelText={"New password"}
                isError={usernameError.error}
                type="password"
                onChange={(e) => setPassword1(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    submitNewPassword();
                  }
                }}
              />
              <LabeledInput
                className={`${LOGIN_STYLE_BASE}_password`}
                labelText={"Re-enter new password"}
                isError={passwordError.error}
                type="password"
                onChange={(e) => setPassword2(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    submitNewPassword();
                  }
                }}
              />
              <div className={`${LOGIN_STYLE_BASE}_error-message`}>
                {usernameError.message || passwordError.message}
              </div>
              <CallToActionButton
                onClick={submitNewPassword}
                title={
                  signInLoading ? <CircularProgress style={{'color': 'white'}} /> : "Submit"
                }
              />
            </div>
            <FiHelpInstructions/>
            <NinthWaveEmpoweredLogo/>
          </PageLayout>
        </Paper>
      </div>
    </>
  );
};

export default withRouter(ResetPasswordPage);
