import React from "react";
import { debounce } from "lodash";
import classNames from 'classnames';
import CallToActionButton from "components/CallToActionButton";

const ButtonPanel = ({ handleClick, STYLE_BASE }) => {
  const handleDebounceClick = debounce(handleClick, 500);

  return (
    <div className={classNames(`${STYLE_BASE}_button-panel`)}>
      <CallToActionButton
        title={"LOG OUT"}
        onClick={handleDebounceClick}
      />
    </div>
  );
}

export default ButtonPanel;