import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.scss";

import LoginPage from "./pages/LoginPage";
import TermsPage from "./pages/TermsPage";
import PrivateRoute from "./components/PrivateRoute";
import ApplicationsPage from "pages/ApplicationsPage";
import AccountsPage from "pages/AccountsPage";
import ApplicationInstructionsPage from "pages/ApplicationInstructionsPage";
import ResetPasswordPage from "pages/ResetPasswordPage";
import ForgotPasswordPage from "pages/ForgotPasswordPage";
import ForgotPasswordSuccessPage from "pages/ForgotPasswordSuccessPage";
import ResetPasswordErrorPage from "pages/ResetPasswordErrorPage";
import PasswordExpiredPage from "pages/PasswordExpiredPage";
import LoginErrorPage from "pages/LoginErrorPage";
import OneTimePasswordPage from "pages/OneTimePasswordPage";

const App = () => {
  const [user, setUser] = useState();

  return (
    <div className="main-app-container">
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/login-error" component={LoginErrorPage} />
        <Route path="/password-expired" component={PasswordExpiredPage} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/forgot-password-success" component={ForgotPasswordSuccessPage} />
        <Route path="/reset-password" component={ResetPasswordPage} />
        <Route path="/reset-password-error" component={ResetPasswordErrorPage} />
        <Route path="/one-time-password" component={OneTimePasswordPage} />
        <PrivateRoute path="/terms" exact component={TermsPage} />
        <PrivateRoute path="/applications" exact component={ApplicationsPage} />
        <PrivateRoute path="/accounts" exact component={AccountsPage} />
        <PrivateRoute path="/application-instructions" exact component={ApplicationInstructionsPage} />
        <PrivateRoute path="/" exact component={LoginPage} />
      </Switch>
    </div>
  );
};

export default App;
