import React, { PureComponent } from 'react';
import classNames from 'classnames';

import './labeled-input.scss';

const STYLE_BASE = 'labeled-input'

class LabeledInput extends PureComponent {
  static defaultProps = {
    text: '',
    placeholder: ''
  }

  render() {
    const {
      isDisabled = false,
      isPassword = false,
      type,
      placeholder,
      labelText,
      value,
      isError = false,
      error = '',
      onBlur,
      onChange,
      onKeyPress,
      className,
      inputRef,
      ...rest
    } = this.props;

    return (
      <div className={classNames(`${STYLE_BASE}`, {
        [className]: !!className
      })}>

        {labelText ? (
          <h2 className={classNames({
            [`${STYLE_BASE}_label`]: true,
          })}>
            {labelText}
          </h2>
        ) : ''}
        <input
          className={classNames(`${STYLE_BASE}_input`,{
            [`${STYLE_BASE}_input_error`]: isError,
          })}
          onChange={e => onChange && onChange(e)}
          onKeyPress={(e) => onKeyPress && onKeyPress(e)}
          placeholder={placeholder}
          ref={inputRef}
          type={type || 'text'}
          value={value}
        />
      </div>
    );
  }
};

export default LabeledInput;
