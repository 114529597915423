import React from 'react';

import './error-wrapper.scss';

const STYLE_BASE = 'error-wrapper_'

const ErrorWrapper = ({ children, error }) => {
  return (
    <div className={`${STYLE_BASE}contentWrapper`}>
      {children}
      <div className={`${STYLE_BASE}main`}>
        {error}
      </div>
    </div>
  )
}

export default ErrorWrapper;