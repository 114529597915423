import React from "react";
import { withRouter } from "react-router-dom";
import GenericMessagePage from "pages/GenericMessagePageTemplate";

const ForgotPasswordSuccessPage = ({ history }) => {
  return (
    <GenericMessagePage
      message={(<>Reset password email successfully sent.<br></br>Please check your email.</>)}
    />
  );
};

export default withRouter(ForgotPasswordSuccessPage);
