import React from "react";
import { withRouter } from "react-router-dom";
import GenericMessagePage from "pages/GenericMessagePageTemplate";
import { FI_ERROR_TIP, FI_NAME } from "data/constants";

const LoginErrorPage = ({ history }) => {
  return (
    <GenericMessagePage
      message={(<>
        Thank you for logging into {FI_NAME}.
        <br></br><br></br>
        {FI_ERROR_TIP}.
        <br></br><br></br>
        Thank you.</>)}
    />
  );
};

export default withRouter(LoginErrorPage);
