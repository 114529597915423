import React from "react";
import { withRouter } from "react-router-dom";
import GenericMessagePage from "pages/GenericMessagePageTemplate";

const PasswordExpiredPage = ({ history }) => {
  return (
    <GenericMessagePage
      message={(<>Your password reset link has expired or is invalid. If you still need to reset your password, you can request a new reset email.</>)}
    />
  );
};

export default withRouter(PasswordExpiredPage);
