import React, { Component } from 'react';

import './cancel-button.scss';

const STYLE_BASE = 'cancel-button_'

export default class CancelButton extends Component {
  onClick = e => {
    const { isDisabled, onClick, bubbleEvent } = this.props;
    if (isDisabled || !bubbleEvent) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!isDisabled) {
      onClick && (bubbleEvent ? onClick(e) : onClick());
    }
  }

  render() {
    const {
      title,
    } = this.props;

    return (
      <div className={`${STYLE_BASE}wrapper`}>
        <button
          onClick={this.onClick}
          className={`${STYLE_BASE}button`}
        >
          <div>
            <span className={`${STYLE_BASE}title`}>{title}</span>
          </div>
        </button>
      </div>
    )
  }
}
