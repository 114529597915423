import React from "react";
import { withRouter } from 'react-router-dom';

import "./application-instructions-page.scss";
import AppHeader from "components/AppHeader";
import PageLayout from "components/MainLayout/PageLayout";
import Header from "components/MainLayout/Header";
import ButtonPanel from "pages/ApplicationInstructionsPage/ButtonPanel";
import { STORAGE_KEY, store } from "data/store";
import { FI_NAME } from "data/constants";
import FiLogo from "components/FiLogo";

const STYLE_BASE = 'application-instructions';

const ApplicationInstructionsPage = ({ history }) => {
  const selectedApp = store.getObject(STORAGE_KEY.SELECTED_APPLICATION);
  const selectedAppName = selectedApp?.fintechRegistry.name?.trim() ?? "???";
  const handleClick = () => {
    history.push("/applications");
  }

  return (
    <>
      <AppHeader />
      <div className={`${STYLE_BASE}_container`}>
        <PageLayout>
          <Header
            titleFirst={
              <FiLogo/>
            }
          />
          <div className={`${STYLE_BASE}_content_wrapper`}>
            <div className={`${STYLE_BASE}_app-logo-container`}>
              <img src={selectedApp?.fintechRegistry.tnpFtrUiAssets.imagePath}/>
            </div>
            <div className={`${STYLE_BASE}_instructions`}>
              You have connected your <b>{selectedAppName}</b> accounts.<br></br>
              <br></br>
              To complete bank connection to {selectedAppName}, log in to {selectedAppName} and enter your {FI_NAME} credentials when prompted during the workflow to add bank accounts.
            </div>
          </div>
          <ButtonPanel
            STYLE_BASE={STYLE_BASE}
            handleClick={handleClick}
          />
        </PageLayout>
      </div>
    </>
  );
};

export default withRouter(ApplicationInstructionsPage);
