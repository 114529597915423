import React, { useState } from "react";
import { debounce } from "lodash";
import classNames from 'classnames';
import { CircularProgress } from "@mui/material";
import CallToActionButton from "components/CallToActionButton";
import CancelButton from "components/CancelButton";

const ButtonPanel = ({ handleSubmitClick, handleCancelClick, STYLE_BASE }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleDebounceClick = debounce(() => handleSubmitClick(setIsLoading), 100);

  return (
    <div className={classNames(`${STYLE_BASE}_button-panel`)}>
      <CancelButton
        onClick={handleCancelClick}
        title={"BACK"}
      />
      <CallToActionButton
        onClick={handleDebounceClick}
        title={
          isLoading ? <CircularProgress style={{'color': 'white'}} /> : "CONTINUE"
        }
      />
    </div>
  );
}

export default ButtonPanel;