import React from 'react';
import classNames from 'classnames';

import './panel.scss';

const STYLE_BASE = 'panel'

const Panel = props => {
  const {
    className,
    children,
  } = props;

  return (
    <div
      className={classNames({
        [`${STYLE_BASE}`]: true,
        [className]: !!className,
      })}
    >
      {children}
    </div>
  );
}

export default Panel;