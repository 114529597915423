import React, { useEffect, useState } from "react";
import { CircularProgress, Paper, } from "@mui/material";

import "./login-page.scss";
import { fetchCustomerAccountsInfo, fetchProfile, fetchSessionStart, fetchUserAuthenticate } from "../../actions/api";
import { STORAGE_KEY, store } from "../../data/store";
import { Link, withRouter } from "react-router-dom";
import { fetchFeaturedClientApplications } from "actions/admin-api";
import { parseQuery } from "utils";
import jwt_decode from "jwt-decode";
import { clearUserSessionData } from "actions/user";
import CallToActionButton from "components/CallToActionButton";
import PageLayout from "components/MainLayout/PageLayout";
import Header from "components/MainLayout/Header";
import FiLogo from "components/FiLogo";
import LabeledInput from "components/LabeledInput";
import { FI_BACKGROUND_URL } from "data/constants";
import Footer from "pages/LoginPage/Footer";

export const STYLE_BASE = 'login';

const LoginPage = ({ history }) => {
  const [signInLoading, setSignInLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [passwordExpirationToken, setPasswordExpirationToken] = useState("");
  const [passwordExpirationTokenUsername, setPasswordExpirationTokenUsername] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState({
    error: false,
    message: "",
  });
  const [passwordError, setPasswordError] = useState({
    error: false,
    message: "",
  });

  useEffect(() => {
    try {
      const query = window.location.search;
      const parsedQuery = parseQuery(query);
      const passwordExpirationToken = parsedQuery.code ?? parsedQuery.token; // TODO: backend should use only 'token' in the url
      if (passwordExpirationToken) {
        const decodedToken = jwt_decode(passwordExpirationToken);
        if (Date.now() >= decodedToken.exp * 1000) {
          throw "token has expired";
        }
        setPasswordExpirationToken(passwordExpirationToken);
        setPasswordExpirationTokenUsername(decodedToken.username);
      }
    } catch (e) {
      console.log(e);
      history.push("/password-expired");
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  async function signIn() {
    setSignInLoading(true);
    setUsernameError({
      ...usernameError,
      error: false,
      message: "",
    });
    setPasswordError({
      ...usernameError,
      error: false,
      message: "",
    });
    try {
      clearUserSessionData();

      const { securityToken: { tokenString } } = await fetchSessionStart();
      store.set(STORAGE_KEY.AUTH, tokenString);

      const { status, payload: { needPasswordReset, customerKey, description, needOtp } } = await fetchUserAuthenticate({ username, password, passwordReset: false });
      //const { status, payload: { needPasswordReset, customerKey, description, needOtp } } = await fetchUser({ username });
      switch(status) {
        case 'FAILED':
          if (needPasswordReset === true) {
            store.setObject(STORAGE_KEY.CREDENTIALS, { username, password });
            if (passwordExpirationToken && passwordExpirationTokenUsername === username) {
              history.push(`/reset-password?token=${passwordExpirationToken}`);
            } else {
              history.push("/reset-password");
            }
            return;
          }
        case 'ERROR':
          if (needOtp) {
            store.setObject(STORAGE_KEY.CREDENTIALS, { username, password });
            history.push("/one-time-password");
            return;
          }
          setPasswordError({
            ...usernameError,
            error: false,
            message: description,
          });
          throw description;
        case 'SUCCESS':
          store.setObject(STORAGE_KEY.CREDENTIALS, { username, password });
          break;
      }

      const profile = await fetchProfile();
      store.setObject(STORAGE_KEY.PROFILE, profile);

      const applications = await fetchFeaturedClientApplications();
      store.setObject(STORAGE_KEY.APPLICATIONS, applications);

      const customerAccountsInfo = await fetchCustomerAccountsInfo({ username, password, profile, customerKey });
      store.setObject(STORAGE_KEY.CUSTOMER_ACCOUNTS_INFO, customerAccountsInfo);

      switch(customerAccountsInfo.code) {
        case '302': // Password reset needed
          if (passwordExpirationToken && passwordExpirationTokenUsername === username) {
            history.push(`/reset-password?token=${passwordExpirationToken}`);
          } else {
            history.push("/reset-password");
          }
          return;
        case '303': // Additional MFA information is needed from the user.
          store.setObject(STORAGE_KEY.CREDENTIALS, { username, password });
          history.push(`/one-time-password?token=${passwordExpirationToken}`);
          return;
        case '304': // "The user has provided incorrect MFA information or the MFA information provided has expired."
          // ??? this should never fire on this page
          break;
      }

      setSignInLoading(false);
      history.push("/terms");
    } catch(error) {
      console.log(error);
      setSignInLoading(false);
      if (store.get(STORAGE_KEY.CREDENTIALS)) {
        // credentials are valid, explain that error is not the user's fault
        history.push("/login-error");
      }
      clearUserSessionData();
    }
  }

  return (
    <>
      <div className={`${STYLE_BASE}_container`} style={{ backgroundImage: `url(${FI_BACKGROUND_URL})` }}>
        <Paper className="form-container">
          <PageLayout>
            <Header
              titleFirst={
                <FiLogo/>
              }
              titleSecond={"Login to your account"}
            />
            <div className={`page-content`}>
              <LabeledInput
                className={`${STYLE_BASE}_username`}
                labelText={"Username"}
                isError={usernameError.error}
                onChange={(e) => setUsername(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    signIn();
                  }
                }}
              />
              <LabeledInput
                className={`${STYLE_BASE}_password`}
                labelText={"Password"}
                isError={passwordError.error}
                type={'password'}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    signIn();
                  }
                }}
              />
              <Link underline="hover" variant="body2" to="/forgot-password" className="form-link">
                Forgot password?
              </Link>
              <div className={`${STYLE_BASE}_error-message`}>
                {usernameError.message || passwordError.message}
              </div>
              <CallToActionButton
                onClick={signIn}
                title={
                  signInLoading ? <CircularProgress style={{'color': 'white'}} /> : "Login"
                }
              />
            </div>
            <Footer/>
          </PageLayout>
        </Paper>
      </div>
    </>
  );
};

export default withRouter(LoginPage);
