import React from "react";
import {Redirect, Route} from "react-router-dom";
import {STORAGE_KEY, store} from "../data/store";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = store.getObject(STORAGE_KEY.CUSTOMER_ACCOUNTS_INFO);
  return (
    <Route
      {...rest}
      render={(props) =>
        user !== null ? (
          <Component {...props} user={user} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
