import React from "react";
import { withRouter } from "react-router-dom";
import GenericMessagePage from "pages/GenericMessagePageTemplate";

const ResetPasswordErrorPage = ({ history }) => {
  return (
    <GenericMessagePage
      message={(<>Reset Password link is invalid or has expired.</>)}
    />
  );
};

export default withRouter(ResetPasswordErrorPage);
