import React, { Component } from 'react';
import classNames from 'classnames';

import 'pages/LoginPage/Footer/footer.scss'
import FiHelpInstructions from "components/FiHelpInstructions";
import NinthWaveEmpoweredLogo from "components/NinthWaveEmpoweredLogo";

const STYLE_BASE = 'login_footer_'

class Footer extends Component {
  render() {
    const {className} = this.props;

    return (
      <div className={classNames(`${STYLE_BASE}wrapper`, {
        [className]: !!className
      })}>
        <FiHelpInstructions/>
        <div className={`${STYLE_BASE}logo-wrapper`}>
          <NinthWaveEmpoweredLogo/>
        </div>
      </div>
    );
  }
}

export default Footer;