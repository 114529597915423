import React from 'react';
import classNames from 'classnames';

import './page-layout.scss';
import { cloneElements } from "utils";

const STYLE_BASE = 'page-layout'

const PageLayout = props => {
  const {
    className,
    children,
    ...rest
  } = props;

  return (
    <div
      className={classNames({
        [`${STYLE_BASE}`]: true,
        [className]: !!className,
      })}
    >
      {cloneElements(children, {
        ...rest
      })}
    </div>
  );
}

export default PageLayout;
