import React from 'react';
import classNames from 'classnames';
import './fi-help-instructions.scss';
import { FI_HELP_INSTRUCTIONS } from "data/constants";

const STYLE_BASE = 'fi-help-instructions';

const FiHelpInstructions = ({ className }) => {
  return (
    <div className={classNames(`${STYLE_BASE}`, {
      [className]: !!className
    })}>
      {FI_HELP_INSTRUCTIONS}
    </div>
  );
}

export default FiHelpInstructions;
