import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import $ from "jquery";

import "./terms-page.scss";
import AppHeader from "components/AppHeader";
import { FI_BANK_NAME, FI_NAME, FI_SHORT_NAME, FI_TOC_URL } from "data/constants";
import ErrorWrapper from "components/ErrorWrapper";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FiLogo from "components/FiLogo";
import Header from "components/MainLayout/Header";
import PageLayout from "components/MainLayout/PageLayout";
import LabeledCheckbox from "components/LabeledCheckbox";
import CallToActionButton from "components/CallToActionButton";

const STYLE_BASE = 'terms';

const TermsPage = ({ history }) => {
  const [userReadTermsToTheEnd, setUserReadTermsToTheEnd] = useState(false);
  const [checked, setChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(
    () => {
      $.get(FI_TOC_URL, async (htmlResponse) => await onHtmlDownloaded(htmlResponse), 'html');
      /*$.get({
        url: FI_TOC_URL,
        onsuccess: onHtmlDownloaded,
        dataType: 'html',
        contentType: 'text/html; charset=windows-1252'
      });*/

      const iframe = document.getElementById('iframe');
      iframe.onload = (e) => {
        e.target.contentWindow.onscroll = (e) => {
          const _window = e.target.defaultView;
          const _document = e.target;
          if ((_window.innerHeight + _window.pageYOffset) >= _document.body.offsetHeight - 10) {
            if (!userReadTermsToTheEnd) {
              setUserReadTermsToTheEnd(true);
            }
          }
        }
      }
    },
    [],
  );

  const onHtmlDownloaded = async (htmlString) => {
    try {
      const patchedHtmlString = await patchHtml(htmlString);
      attachHtmlToIFrame(patchedHtmlString);
    } catch(e) {
      console.error('onHtmlDownloaded error', e);
    }
  }

  const attachHtmlToIFrame = (htmlString) => {
    const htmlBlob = new Blob([htmlString], {type : 'text/html'});
    const htmlBlobUrl = URL.createObjectURL(htmlBlob);
    document.getElementById('iframe').src = htmlBlobUrl;
  }

  const patchHtml = async (html) => {
    let patched = html;
    let htmlCss = "";
    await $.get({
      url: '/toc-html.css',
      mimeType: 'css',
      success: (cssResponse) => { htmlCss = cssResponse; }
    });

    // temporary for the demo - dirty fix for bad encoding
    patched = patched.replace(/�/g, "'");

    if (patched.includes('</head>')) {
      patched = patched.replace('</head>',
        ` <style>
            ${htmlCss}
            </style>
          </head>
        `
      );
    }
    return patched;
  }

  const accept = () => {
    history.push("/applications");
  };

  return (
    <>
      <AppHeader />
      <div className={`${STYLE_BASE}_container`}>
        <PageLayout>
          <Header
            titleFirst={
              <FiLogo/>
            }
            titleSecond={"Terms and Conditions"}
          />
          <div className="terms">
            <div className={`${STYLE_BASE}_intro`}>
              {FI_NAME} (“{FI_SHORT_NAME}”) connects our customers
              to their {FI_BANK_NAME} account data within their
              third-party business applications. Please take a moment
              to review and accept the {FI_NAME} Terms and Conditions, which governs your use of {FI_SHORT_NAME}.
              <br></br><br></br>
              Once you have accepted these terms, you can use the Consent and Connect page
              to enable account sharing for each eligible application. You can also use
              Connect and Connect to manage account sharing in the future.
            </div>
            <div>
              <Accordion onChange={(event, expanded) => {
                // iPhone scroll fix: force browser to reprocess iframe after Accordion is expanded
                if (expanded) {
                  const iframe = document.getElementById("iframe");
                  if (iframe) {
                    iframe.style.width = "99%"; // changing 100% to 99%
                  }
                }
              }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography component={'div'}>Terms and Conditions</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component={'div'}>
                    <iframe id={'iframe'} />
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className={`${STYLE_BASE}_accept-checkbox-wrapper`}>
              <LabeledCheckbox
                isDisabled={!userReadTermsToTheEnd}
                isChecked={checked}
                onChange={() => setChecked(!checked)}
                onClick={() => {
                  setErrorMessage(userReadTermsToTheEnd ? "" : `Before continuing the login process, you must view the ${FI_NAME} Terms and Conditions found above.`)
                }}
                label={"I, Acknowledge and Accept the Terms and Conditions herein."}
              />
              <ErrorWrapper error={errorMessage}/>
            </div>
            <div className={`${STYLE_BASE}_outro`}>
              Thank you for partnering with {FI_BANK_NAME} and we look
              forward to providing you with connections to the applications you need to succeed.
            </div>
          </div>
          <CallToActionButton
            onClick={accept}
            isDisabled={!checked}
            title={"Continue"}
          />
        </PageLayout>
      </div>
    </>
  );
};

export default withRouter(TermsPage);
