import DefaultBackgroundImage from "assets/images/login-background.svg";

export const BASE_URL = process.env.REACT_APP_BASE_URL?.trim() || "https://qa-services.1fsapi.com/nwplatform-rco/trustednetwork";
export const ADMIN_BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL?.trim() || "https://tnp.liqui.fi/tnp-dev/adminsvc";
export const APP_ID = process.env.REACT_APP_APP_ID?.trim() || "C940F882-86B4-4822-9A3D-B7ED92218E36";
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID?.trim() || "STIFaedfba3eb71f11eb85290242ac130003";
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET?.trim() || "0232b3290a9845fdaaea361734d80ec7";
export const FI_NAME = process.env.REACT_APP_FI_NAME?.trim() || "Stifel Bank Treasury Central Connect";
export const FI_BANK_NAME = process.env.REACT_APP_FI_BANK_NAME?.trim() || "Stifel Bank";
export const FI_SHORT_NAME = process.env.REACT_APP_FI_SHORT_NAME?.trim() || "TCC";
export const FI_TOC_URL = process.env.REACT_APP_FI_TOC_URL?.trim() || "https://d35dbchpzghd75.cloudfront.net/stifel-tm_tc/TCConnectTermsforNWLayout_filtered.htm";
export const FI_LOGO_URL = process.env.REACT_APP_FI_LOGO_URL?.trim() || "https://imgs.tnp.services/tnp-local/MTYzNDIyNTk5NTQ4MC1UcmVhc3VyeUNlbnRyYWxDb25uZWN0LnBuZw==";
export const FI_BACKGROUND_URL = process.env.REACT_APP_FI_BACKGROUND_URL?.trim() || DefaultBackgroundImage;
export const USER_FIRST_NAME = process.env.REACT_APP_USER_FIRST_NAME?.trim() || "FIAT";
export const USER_LAST_NAME =  process.env.REACT_APP_USER_LAST_NAME?.trim() || "USER 1";
export const USER_EMAIL = process.env.REACT_APP_USER_EMAIL?.trim() || "fiatuser1@stifel.com";
export const FI_ID = process.env.REACT_APP_FI_ID?.trim() || 44001;
export const FI_HELP_INSTRUCTIONS = process.env.REACT_APP_FI_HELP_INSTRUCTIONS;
export const FI_ERROR_TIP = process.env.REACT_APP_FI_ERROR_TIP;
export const FI_FEATURES = process.env.REACT_APP_FI_FEATURES?.trim().split(',') || [];

export const IS_PRODUCTS_FEATURE_ENABLED = FI_FEATURES.includes('PRODUCTS');
export const IS_OTP_FEATURE_ENABLED = FI_FEATURES.includes('OTP');

// account types that the backend is expected to use in its response
export const EXPECTED_ACCOUNT_TYPES = [ 'depositAccount', 'loanAccount', 'locAccount', 'investmentAccount' ];