import React, { useEffect, useState } from "react";
import { CircularProgress, Paper } from "@mui/material";

import "./one-time-password-page.scss";
import { fetchCustomerAccountsInfo } from "actions/api";
import { STORAGE_KEY, store } from "data/store";
import { withRouter } from "react-router-dom";
import FiHelpInstructions from "components/FiHelpInstructions";
import PageLayout from "components/MainLayout/PageLayout";
import Header from "components/MainLayout/Header";
import FiLogo from "components/FiLogo";
import LabeledInput from "components/LabeledInput";
import CallToActionButton from "components/CallToActionButton";

import { STYLE_BASE as LOGIN_STYLE_BASE } from '../LoginPage';
import { clearUserSessionData } from "actions/user";
import { parseQuery } from "utils";
import jwt_decode from "jwt-decode";
import { FI_BACKGROUND_URL } from "data/constants";
import NinthWaveEmpoweredLogo from "components/NinthWaveEmpoweredLogo";

const STYLE_BASE = 'one-time-password';

const OneTimePasswordPage = ({ history }) => {
  const credentials = store.getObject(STORAGE_KEY.CREDENTIALS);
  const profile = store.getObject(STORAGE_KEY.PROFILE);
  const customerAccountsInfo = store.getObject(STORAGE_KEY.CUSTOMER_ACCOUNTS_INFO);

  const [signInLoading, setSignInLoading] = useState(false);
  const [passwordExpirationToken, setPasswordExpirationToken] = useState("");
  const [passwordExpirationTokenUsername, setPasswordExpirationTokenUsername] = useState("");
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState({
    error: false,
    message: "",
  });

  useEffect(() => {
    try {
      const query = window.location.search;
      const parsedQuery = parseQuery(query);
      const passwordExpirationToken = parsedQuery.code ?? parsedQuery.token; // TODO: backend should use only 'token' in the url
      if (passwordExpirationToken) {
        const decodedToken = jwt_decode(passwordExpirationToken);
        if (Date.now() >= decodedToken.exp * 1000) {
          throw "token has expired";
        }
        setPasswordExpirationToken(passwordExpirationToken);
        setPasswordExpirationTokenUsername(decodedToken.username);
      }
    } catch (e) {
      console.log(e);
      history.push("/password-expired");
    }
  }, []);

  async function submitOtp() {
    setSignInLoading(true);
    setOtpError({
      ...otpError,
      error: false,
      message: "",
    });
    try {
      const { username, password } = credentials;
      const { customerKey } = customerAccountsInfo;

      const updatedCustomerAccountsInfo = await fetchCustomerAccountsInfo({ username, password, otp, profile, customerKey });
      store.setObject(STORAGE_KEY.CUSTOMER_ACCOUNTS_INFO, updatedCustomerAccountsInfo);

      const { code, message } = updatedCustomerAccountsInfo;
      switch(code) {
        case '302': // Password reset needed
          if (passwordExpirationToken && passwordExpirationTokenUsername === username) {
            history.push(`/reset-password?token=${passwordExpirationToken}`);
          } else {
            history.push("/reset-password");
          }
          return;
        case '303': // Additional MFA information is needed from the user.
          // ??? we just provided OTP
          return;
        case '304': // "The user has provided incorrect MFA information or the MFA information provided has expired."
          setOtpError({
            ...otpError,
            error: true,
            message: message,
          });
          throw message;
          break;
      }

      setSignInLoading(false);
      history.push("/terms");
    } catch(error) {
      console.log(error);
      setSignInLoading(false);
      if (typeof error !== 'string') {
        // credentials are valid, explain that error is not the user's fault
        history.push("/login-error");
        clearUserSessionData();
      }
    }
  }

  return (
    <>
      <div className={`${LOGIN_STYLE_BASE}_container ${STYLE_BASE}_container`} style={{ backgroundImage: `url(${FI_BACKGROUND_URL})` }}>
        <Paper className="form-container">
          <PageLayout>
            <Header
              titleFirst={
                <FiLogo/>
              }
              titleSecond={"Please enter your otp"}
            />
            <div className={`page-content`}>
              <LabeledInput
                className={`${LOGIN_STYLE_BASE}_username`}
                labelText={"One time password"}
                isError={otpError.error}
                type="otp"
                onChange={(e) => setOtp(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    submitOtp();
                  }
                }}
              />
              <div className={`${LOGIN_STYLE_BASE}_error-message`}>
                {otpError.message}
              </div>
              <CallToActionButton
                onClick={submitOtp}
                title={
                  signInLoading ? <CircularProgress style={{'color': 'white'}} /> : "Submit"
                }
              />
            </div>
            <FiHelpInstructions/>
            <NinthWaveEmpoweredLogo/>
          </PageLayout>
        </Paper>
      </div>
    </>
  );
};

export default withRouter(OneTimePasswordPage);
