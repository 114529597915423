import React from "react";
import { ReactComponent as NinthWaveLogo } from "assets/images/NW_Logo_Empowered_Horizontal.svg";

import './ninth-wave-empowered-logo.scss';

const NinthWaveEmpoweredLogo = () => {
  return (
    <div className="ninth-wave-empowered-logo-container">
      <NinthWaveLogo/>
    </div>
  );
};

export default NinthWaveEmpoweredLogo;