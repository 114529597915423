import React from "react";
import { withRouter } from 'react-router-dom';

import "./applications-page.scss";
import AppHeader from "components/AppHeader";
import PageLayout from "components/MainLayout/PageLayout";
import Button from "components/Button";
import Panel from "components/Panel";
import Header from "components/MainLayout/Header";
import ButtonPanel from "pages/ApplicationsPage/ButtonPanel";
import { clearUserSessionData } from "actions/user";
import { STORAGE_KEY, store } from "data/store";
import { EXPECTED_ACCOUNT_TYPES, FI_NAME } from "data/constants";
import FiLogo from "components/FiLogo";

const STYLE_BASE = 'applications';

const ApplicationsPage = ({ history }) => {
  const customerAccountsInfo = store.getObject(STORAGE_KEY.CUSTOMER_ACCOUNTS_INFO);
  const applications = store.getObject(STORAGE_KEY.APPLICATIONS);
  // mock for tests in case backend fails to work
  const applications_ = [
    {
      name: "quickbooks Desktop",
      imageSrc: "https://res.cloudinary.com/ninth-wave/image/upload/v1632877144/Applications/QBD_c5de3g.png",
      accounts: [...customerAccountsInfo?.depositAccount ?? [], ...customerAccountsInfo?.locAccount ?? []],
      instructions: `To complete your QuickBooks connection, log in to QuickBooks and enter your ${FI_NAME} credentials when prompted on the Bank Feed menu.`
    },
    {
      name: "quickbooks Online",
      imageSrc: "https://res.cloudinary.com/ninth-wave/image/upload/v1632877147/Applications/QBO-Logo_gyrzxu.png",
      accounts: [...customerAccountsInfo?.depositAccount ?? [], ...customerAccountsInfo?.locAccount ?? []],
      instructions: `To complete your QuickBooks connection, log in to QuickBooks and enter your ${FI_NAME} credentials when prompted on the Bank Feed menu.`
    }
  ];

  const handleLogoutClick = () => {
    clearUserSessionData();
    history.push("/");
  }

  return (
    <>
      <AppHeader />
      <div className={`${STYLE_BASE}_container`}>
        <PageLayout>
          <Header
            titleFirst={
              <FiLogo/>
            }
            titleSecond={"Connect your accounts"}
            titleThird={"Please select the applications you would like to manage."}
          />
          <Panel className={`${STYLE_BASE}_list`}>
            {applications.map((app) => (
              <div className={`${STYLE_BASE}_list-item`} key={app.fintechRegistry.tnpCncUseCase.useCaseName}>
                <Button
                  onClick={() => {
                    store.setObject(STORAGE_KEY.SELECTED_APPLICATION, app);
                    history.push("/accounts")
                  }}
                >
                  <img src={app.fintechRegistry.tnpFtrUiAssets.imagePath}/>
                  <div className={`${STYLE_BASE}_list-item_status`}>
                    {customerAccountsInfo.associatedApplications?.filter(associatedApp =>
                        associatedApp.applicationKey === app.applicationKey
                      )[0]?.consentedAccounts.length ?? 0}
                    /
                    {EXPECTED_ACCOUNT_TYPES.map(accountType =>
                        customerAccountsInfo[accountType]?.length ?? 0
                      ).reduce((partialSum, accountCountForType) => partialSum + accountCountForType, 0)} accounts connected
                  </div>
                </Button>
              </div>)
            )}
          </Panel>
          <ButtonPanel
            STYLE_BASE={STYLE_BASE}
            handleClick={handleLogoutClick}
          />
        </PageLayout>
      </div>
    </>
  );
};

export default withRouter(ApplicationsPage);
