import React, { Component } from 'react';
import classNames from 'classnames';

import './multi-checkbox-choice.scss';
import LabeledCheckbox from "../LabeledCheckbox";
import * as utils from "../../utils";

const STYLE_BASE = 'multi-checkbox-choice_';

class MultiCheckboxChoice extends Component {

  handleSelectAllCheckboxChange = (key, isChecked) => {
    const { options, onChange } = this.props;
    const newSelectedValue = isChecked ? options.map(option => option.value) : [];
    onChange && onChange(newSelectedValue);
  }

  handleOptionCheckboxChange = (key, isChecked) => {
    const { noneOption, options, onChange } = this.props;
    const selectedValue = [...this.props.selectedValue];

    let newSelectedValue = selectedValue;
    if (isChecked) {
      if (noneOption?.value === key) {
        // if 'none' option is selected, then clear everything else
        newSelectedValue = [noneOption.value];
      } else {
        if (!selectedValue.includes(key)) {
          selectedValue.push(key);
          // keep only actual selected option values (i.e. clear 'none' option)
          const filteredOptionsValues = options.filter(option => selectedValue.includes(option.value)).map(option => option.value);
          newSelectedValue = filteredOptionsValues;
        }
      }
    } else {
      if (utils.removeArrayElementOnce(selectedValue, key)) {
        newSelectedValue = selectedValue;
      }
    }
    onChange && onChange(newSelectedValue);
  }

  render() {
    const { selectedValue, noneOption, options, selectAllLabel, customItemPostfixGenerator } = this.props;
    const optionsFirstRowLength = Math.ceil(options.length / 2);
    return (
      <div className={`${STYLE_BASE}container`}>
        {selectAllLabel && (
          <>
            <div className={`${STYLE_BASE}selectAllCheckboxContainer`}>
              <LabeledCheckbox
                className={classNames(
                  `${STYLE_BASE}checkboxElementWrapper`,
                  `${STYLE_BASE}lastCheckboxElementWrapper`
                )}
                label={selectAllLabel}
                value={selectAllLabel}
                isChecked={options.every(option => selectedValue.includes(option.value))}
                onChange={this.handleSelectAllCheckboxChange}
              />
            </div>
            <div className={`${STYLE_BASE}selectAllCheckboxSeparator`}/>
          </>
        )}
        <div className={`${STYLE_BASE}checkboxListContainer`}>
          <div className={`${STYLE_BASE}checkboxRow`}>
            {
              options.slice(0, optionsFirstRowLength).map(option => (
                <div
                  className={`${STYLE_BASE}checkboxContainer`}
                  key={option.value}
                >
                  <LabeledCheckbox
                    key={option.value}
                    label={option.label}
                    value={option.value}
                    isChecked={selectedValue.includes(option.value)}
                    onChange={this.handleOptionCheckboxChange}
                  />
                  {customItemPostfixGenerator && customItemPostfixGenerator(option)}
                </div>
              ))
            }
          </div>
          <div className={`${STYLE_BASE}checkboxRow`}>
            {
              options.slice(optionsFirstRowLength).map(option => (
                <div
                  className={`${STYLE_BASE}checkboxContainer`}
                  key={option.value}
                >
                  <LabeledCheckbox
                    key={option.value}
                    label={option.label}
                    value={option.value}
                    isChecked={selectedValue.includes(option.value)}
                    onChange={this.handleOptionCheckboxChange}
                  />
                  {customItemPostfixGenerator && customItemPostfixGenerator(option)}
                </div>
              ))
            }
          </div>
        </div>
        {noneOption && (
          <div>
            <LabeledCheckbox
              className={classNames(
                `${STYLE_BASE}checkboxElementWrapper`,
                `${STYLE_BASE}lastCheckboxElementWrapper`
              )}
              label={noneOption.label}
              value={noneOption.value}
              isChecked={selectedValue.includes(noneOption.value)}
              onChange={this.handleOptionCheckboxChange}
            />
          </div>
        )}
      </div>
    )
  };
}

export default MultiCheckboxChoice;
